//* Imports
// Angular
import { Injectable } from '@angular/core';
// Amplify
import { API, Auth } from 'aws-amplify';
// Rxjs
import { BehaviorSubject } from 'rxjs';
// Local Services
import { ErrorService } from '../errorService/error.service';
import { UtilityService } from '../utilityService/utility.service';
// Local Classes
import { User } from '../../classes/user/user';
// Local Interfaces
import { IUserFieldsToRemove } from 'src/app/interfaces/iuser-fields-to-remove.interface';
import { TimerService } from '../timerService/timer.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  //* Variable Declarations
  private blankUser: User = { id: '', loggedIn: false };

  // Local user / cognito user
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(this.blankUser);
  private _cognitoUser: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private dataStore: { user: User; cognitoUser: any } = {
    user: this.blankUser,
    cognitoUser: {},
  };

  // Observable user / cognito user
  readonly user = this._user.asObservable();
  readonly cognitoUser = this._cognitoUser.asObservable();

  //* Constructor
  constructor(
    private errorService: ErrorService,
    private utilityService: UtilityService,
    private timerService: TimerService
  ) {}

  //* Methods
  /**
   * Log a user in by attaching their Dynamo user to their Cognito user from sign in
   * @param payload The response given by Auth.signIn()
   */
  async logInUser(payload: any) {
    try {
      if (payload.data.signInUserSession.idToken.payload) {
        // Get the cognito user data from the cognito response
        const cognitoUser = payload.data.signInUserSession.idToken.payload;
        this.dataStore.cognitoUser = cognitoUser;
        // Update cognito user observable
        this._cognitoUser.next(Object.assign({}, this.dataStore).cognitoUser);
        this.dataStore.user = {
          id: cognitoUser['sub'],
          phone: cognitoUser['phone'],
          email: cognitoUser['email'],
          loggedIn: payload.event === 'signIn',
        };
        // Update user observable
        await this._user.next(Object.assign({}, this.dataStore.user));

        // Get the data for the new user
        await this.getUserData(cognitoUser.sub);
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Get userdata given a cognito id
   * @param cognitoId Cognito id with corresponding data
   */
  private async getUserData(cognitoId: string) {
    try {
      let userData = {};
      const data = await API.get('cwApi', `/user/${cognitoId}`, {});

      // Create user if it does not exist
      if (JSON.stringify(data) === '{}') userData = { ...(await this.createUser(cognitoId)) };
      // Get user info if it does exist
      else userData = { ...data };

      if (JSON.stringify(userData) === '{}') return; // Return if the newly created userdata is empty (an error)

      for (const key in userData) this.dataStore.user[key] = userData[key]; // Set the local data to match the new data
      this._user.next(Object.assign({}, this.dataStore).user); // Update the observable
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Create a user in the DynamoDB
   * @param cognitoId Cognito user to whom the Dynamo user will be attached
   */
  private async createUser(cognitoId: string) {
    try {
      // User Template
      const tempUser: User = {
        id: cognitoId,
        phone: this.dataStore.user.phone,
        email: this.dataStore.user.email,
      };
      const createResponse = await API.post('cwApi', '/user', { body: tempUser });
      return createResponse;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Logs the user out
   */
  async logoutUser() {
    try {
      // Attempt cognito global sign out
      await Auth.signOut({ global: true }).catch((error) => this.errorService.handleError(error));

      // Update dataStore with blank values (no current user)
      this.dataStore = { user: this.blankUser, cognitoUser: {} };
      this._cognitoUser.next(Object.assign({}, this.dataStore).cognitoUser);
      this._user.next(Object.assign({}, this.dataStore).user);
      this.timerService.cleanUp();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Update the user in the database with the updated user data
   * @param userData Updated user data
   */
  async updateUser(userData: User) {
    try {
      const preppedUser = userData;
      const response = await API.put('cwApi', `/user/${userData.id}`, { body: preppedUser });
      // Update dataStore if the values were successfully saved
      if (this.utilityService.isValidResponse(response)) {
        const keys = Object.keys(preppedUser);
        for (const key of keys) this.dataStore.user[key] = preppedUser[key];
      }
      this._user.next(Object.assign({}, this.dataStore.user));
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Remove user fields given a user id and fields
   * @param userFields \{id: 'xxx', fields: ['field1', 'field2', etc.]}
   */
  async removeUserFields(userFields: IUserFieldsToRemove) {
    try {
      await API.put('cwApi', `/user/${userFields.id}/remove`, {
        // Remove Dynamo data
        body: { fields: userFields.fields },
      });
      // Remove data fields
      for (let i = 0; i < userFields.fields.length; i++) {
        delete this.dataStore.user[userFields.fields[i]];
      }
      // Update the data
      this._user.next(Object.assign({}, this.dataStore.user));
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Update the signature given a file key
   * @param fileKey The key to be updated to
   */
  async updateSignature(fileKey: string) {
    try {
      const userData = {
        id: this.dataStore.user.id,
        signatureKey: fileKey,
      };

      await this.updateUser(userData);

      // Update local user data store
      this.dataStore.user['signatureKey'] = fileKey;
      this._user.next(Object.assign({}, this.dataStore).user);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * determines if user is logged in or not
   */
  async isLoggedIn() {
    try {
      return this.dataStore.user.loggedIn;
    } catch (error) {
      this.errorService.handleError(error);
      return false;
    }
  }

  /**
   * Update the ts of when the pw was last updated
   * @param id AWS ID of the user
   */
  public passwordAgeUpdate(id: string) {
    this.updateUser({
      id: id,
      pwLastUpdated: new Date().toISOString(),
    });
  }
}
