import { ContactTime } from '../contactTime/contact-time';
import { Evidence } from '../evidence/evidence';
import { Note } from '../note/note';
import { Search } from '../search/search';
import { SearchWarrant } from '../searchWarrant/search-warrant';

// Response sent back to the police department when the court approves or denies the warrant
export class CourtResponse {
  constructor(
    {
      publicNotes,
      noKnockIsStruck,
      serviceTimeIsStruck,
      isDelayedIsStruck,
      isPhotoIsStruck,
      isPrintsIsStruck,
      isSalivaIsStruck,
      isBloodIsStruck,
      isUrineIsStruck,
      isHairIsStruck,
      isHandwritingIsStruck,
      isMeasurementsIsStruck,
      isVoiceIsStruck,
      isFootprintsIsStruck,
      sealedIsStruck,
      courtStatus,
      tsCourtReviewed,
      courtExplanation,
      contactedTimes,
      oathTimes,
      noAnswerTimes,
      reviewerSignature,
      courtReportNumber,
      reviewerFirstName,
      reviewerLastName,
      reviewerTitle,
    }: SearchWarrant,
    responseEvidences: CourtResponseEvidence[],
    responseSearches: CourtResponseSearch[]
  ) {
    this.evidences = responseEvidences;
    this.searches = responseSearches;
    this.publicNotes = publicNotes;
    this.noKnockIsStruck = noKnockIsStruck;
    this.serviceTimeIsStruck = serviceTimeIsStruck;
    this.isDelayedIsStruck = isDelayedIsStruck;
    this.isPhotoIsStruck = isPhotoIsStruck;
    this.isPrintsIsStruck = isPrintsIsStruck;
    this.isSalivaIsStruck = isSalivaIsStruck;
    this.isBloodIsStruck = isBloodIsStruck;
    this.isUrineIsStruck = isUrineIsStruck;
    this.isHairIsStruck = isHairIsStruck;
    this.isHandwritingIsStruck = isHandwritingIsStruck;
    this.isMeasurementsIsStruck = isMeasurementsIsStruck;
    this.isVoiceIsStruck = isVoiceIsStruck;
    this.isFootprintsIsStruck = isFootprintsIsStruck;
    this.sealedIsStruck = sealedIsStruck;
    this.courtStatus = courtStatus;
    this.tsCourtReviewed = tsCourtReviewed;
    this.courtExplanation = courtExplanation;
    this.contactedTimes = contactedTimes;
    this.oathTimes = oathTimes;
    this.noAnswerTimes = noAnswerTimes;
    if (courtStatus == 'approved' && reviewerSignature?.length)
      this.reviewerSignature = reviewerSignature;
    this.courtReportNumber = courtReportNumber;
    this.reviewerFirstName = reviewerFirstName;
    this.reviewerLastName = reviewerLastName;
    this.reviewerTitle = reviewerTitle;
  }
  //-----Info-----//
  /** AWS ID of the search warrant */
  id?: string;

  //-----Nested Objects-----//
  /** Evidences to be collected or siezed */
  evidences?: CourtResponseEvidence[];
  /** Persons / Premises / Vehicles / Other objects to be searched */
  searches?: CourtResponseSearch[];

  //-----Notes-----//
  /** Notes seen in the public notes page */
  publicNotes?: Note[];

  //-----Is Struck-----//
  /** If the no knock is struck by the court */
  noKnockIsStruck?: boolean;
  /** If the service time is struck by the court */
  serviceTimeIsStruck?: boolean;
  /** If the court strikes the delayed notice request */
  isDelayedIsStruck?: boolean;
  /** If the photo request struck by the court */
  isPhotoIsStruck?: boolean;
  /** If the print request struck by the court */
  isPrintsIsStruck?: boolean;
  /** If the saliva request struck by the court */
  isSalivaIsStruck?: boolean;
  /** If the blood request struck by the court */
  isBloodIsStruck?: boolean;
  /** If the urine request struck by the court */
  isUrineIsStruck?: boolean;
  /** If the hair request struck by the court */
  isHairIsStruck?: boolean;
  /** If the Handwriting request struck by the court */
  isHandwritingIsStruck?: boolean;
  /** If the measurements request struck by the court */
  isMeasurementsIsStruck?: boolean;
  /** If the voice request struck by the court */
  isVoiceIsStruck?: boolean;
  /** If the foorprints request struck by the court */
  isFootprintsIsStruck?: boolean;
  /** If the request to be sealed has been struck by the court */
  sealedIsStruck?: boolean;

  //-----Review Info-----//
  /** Status of the warrant within the court: submitted, approved, denied */
  courtStatus?: string;
  /** Times the officer was contacted about the warrant */
  contactedTimes?: ContactTime[];
  /** Times the oath was given to the judge */
  oathTimes?: ContactTime[];
  /** Times the judge attempted to contact the officer and was unsuccessful */
  noAnswerTimes?: ContactTime[];
  /** Information on why the warrant was denied */
  courtExplanation?: string;
  /** Report number issued by the court */
  courtReportNumber?: string;

  //-----Reviewer Info-----//
  /** Reviewer first name */
  reviewerFirstName: string;
  /** Reviewer last name */
  reviewerLastName: string;
  /** Reviewer signature */
  reviewerSignature?: string;
  /** Reviewer title */
  reviewerTitle: string;

  //-----Time Stamps-----//
  /** Timestamp of when the court order was approved */
  tsCourtReviewed: string;
}

export class CourtResponseEvidence {
  constructor({ id, isStruck }: Evidence) {
    this.id = id;
    this.isStruck = isStruck;
  }

  /** AWS ID of the evidenceItem */
  id: string;
  /** If the evidence item has been struck of not */
  isStruck?: boolean;
}

export class CourtResponseSearch {
  constructor({ id, isStruck }: Search) {
    this.id = id;
    this.isStruck = isStruck;
  }

  /** AWS ID of the searchItem */
  id: string;
  /** If the search item has been struck of not */
  isStruck?: boolean;
}
