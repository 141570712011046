//* Imports
// Angular
import { Injectable } from '@angular/core';
// Rxjs
import { BehaviorSubject, from } from 'rxjs';
// Amplify
import { API } from 'aws-amplify';
// Local Services
import { UserService } from '../userService/user.service';
import { ErrorService } from '../errorService/error.service';
import { UtilityService } from '../utilityService/utility.service';
// Local Classes
import { SearchWarrant } from 'src/app/classes/searchWarrant/search-warrant';
import { User } from 'src/app/classes/user/user';
import { Search } from 'src/app/classes/search/search';
import { Evidence } from 'src/app/classes/evidence/evidence';
import { IAgency } from 'src/app/classes/agency/IAgency';
import {
  CourtResponse,
  CourtResponseEvidence,
  CourtResponseSearch,
} from 'src/app/classes/courtResponse/court-response';
import { Court } from 'src/app/classes/court/court';

@Injectable({
  providedIn: 'root',
})
export class WarrantDataService {
  //* Variable Declaration
  // Observables
  private _searchWarrants = new BehaviorSubject<SearchWarrant[]>([]);
  private _currentWarrant = new BehaviorSubject<SearchWarrant>({});
  private dataStore: {
    searchWarrants: SearchWarrant[];
    currentWarrant: SearchWarrant;
  };
  readonly searchWarrants = this._searchWarrants.asObservable();
  readonly currentWarrant = this._currentWarrant.asObservable();

  private user$: User;

  //* Constructor
  constructor(
    private userService: UserService,
    private errorService: ErrorService,
    private utilityService: UtilityService
  ) {
    // Set up empty dataStore
    this.dataStore = { searchWarrants: [], currentWarrant: {} };

    this.userService.user.subscribe(
      (data) => {
        this.user$ = data;
        if (this.user$.id != '') this.loadAll();
        else {
          this._searchWarrants.next(Object.assign({}, this.dataStore).searchWarrants);
          this._currentWarrant.next(Object.assign({}, this.dataStore).currentWarrant);
        }
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  //* Search warrant methods
  /**
   * Load all warrants in the court database
   */
  async loadAll() {
    try {
      // Call API to get warrants
      const apiReponse = await from(API.get('cwApi', '/warrant/all', {}));

      // Subscribe to the changes
      apiReponse.subscribe((warrants: SearchWarrant[]) => {
        // Set the warrants to blank before populating them
        this.dataStore.searchWarrants = [];

        // Append each of the warrants to the current warrants
        warrants.forEach((warrant: SearchWarrant) => this.dataStore.searchWarrants.push(warrant));

        this._searchWarrants.next(Object.assign({}, this.dataStore.searchWarrants));
      });
    } catch (error) {
      this.utilityService.endLoading();
      this.errorService.handleError(error);
    }
  }

  /**
   * Load a search warrant by its ID
   * @param warrantId The ID of the search warrant
   */
  async loadWarrantById(warrantId: string) {
    // Begin Loading to be ended on navigation service setJump()
    await this.utilityService.startLoading('Loading Warrant ...');
    try {
      // Set current warrant to blank (to be added to)
      this.dataStore.currentWarrant = new SearchWarrant();

      // Get data from the backend
      const apiReponse = await from(API.get('cwApi', `/warrant/${warrantId}`, {}));
      apiReponse.subscribe(async (warrantFromBackend) => {
        // Update the dataStore from backend
        this.dataStore.currentWarrant = warrantFromBackend;

        // update warrant list with latest status for current warrant
        this.dataStore.searchWarrants.forEach((item) => {
          if (item.id === warrantId && item.status != this.dataStore.currentWarrant.status) {
            item.status = this.dataStore.currentWarrant.status;
          }
        });
        // Load Nested objects
        await this.loadSearchesByWarrantId(warrantId);
        await this.loadEvidencesByWarrantId(warrantId);

        // Update Datastore
        this._searchWarrants.next(Object.assign({}, this.dataStore).searchWarrants);
        this._currentWarrant.next(Object.assign({}, this.dataStore).currentWarrant);
      });
    } catch (error) {
      this.utilityService.endLoading();
      this.errorService.handleError(error);
    }
  }

  public async deleteWarrantById(id: string) {
    try {
      const apiResponse = await API.del('cwApi', `/warrant/${id}`, {}); // Delete from the database
      // If the warrant was successfully deleted
      if (typeof apiResponse.success != 'undefined' && apiResponse.success) {
        this.dataStore.searchWarrants.forEach((item, index) => {
          if (item.id == id) this.dataStore.searchWarrants.splice(index, 1); // Delete from the local storage
        });
        this.clearCurrent();
        this._searchWarrants.next(Object.assign({}, this.dataStore).searchWarrants);
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Update Search Warrant
   * @param searchWarrant searchWarrant to be updated
   */
  async updateWarrant(searchWarrant: SearchWarrant) {
    try {
      // Remove Null or Undefined values
      const preppedSearchWarrant: SearchWarrant = await this.utilityService.removeNullOrUndefined(
        this.utilityService.copyByValue(searchWarrant)
      );

      // Make the call to update the warrant with the new warrant
      const savedWarrant = await API.put('cwApi', `/warrant/${preppedSearchWarrant.id}`, {
        body: preppedSearchWarrant,
      });

      // update current warrant for any missing items or those where the value doesn't match
      Object.keys(savedWarrant).forEach((field) => {
        if (
          typeof this.dataStore.currentWarrant[field] === 'undefined' ||
          this.dataStore.currentWarrant[field] !== savedWarrant[field]
        ) {
          this.dataStore.currentWarrant[field] = savedWarrant[field];
        }
      });

      // Load Nested objects
      await this.loadSearchesByWarrantId(searchWarrant.id);
      await this.loadEvidencesByWarrantId(searchWarrant.id);

      // Update the warrants
      this.syncWarrantsWithCurrent();
      this._searchWarrants.next(Object.assign({}, this.dataStore).searchWarrants);
      this._currentWarrant.next(Object.assign({}, this.dataStore).currentWarrant);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  //* Offense Methods
  /**
   * Load nested offense items for a warrant
   * @param warrantId The ID of the warrant
   */
  private async loadOffensesByWarrantId(warrantId: string) {
    try {
      const offenses = await API.get('cwApi', `/offense/warrant/${warrantId}`, {});
      this.dataStore.currentWarrant.offenses = offenses;
      this.syncWarrantsWithCurrent();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  //* Search Methods
  /**
   * Load nested search items for a warrant
   * @param warrantId The ID of the warrant
   */
  private async loadSearchesByWarrantId(warrantId: string) {
    try {
      const searches = await API.get('cwApi', `/searchitem/warrant/${warrantId}`, {});
      this.dataStore.currentWarrant.searches = searches;
      this.syncWarrantsWithCurrent();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Update a given search item
   * @param search Search item to be updated
   */
  public async updateSearch(search: Search) {
    try {
      const preppedSearch = this.utilityService.removeNullOrUndefined(search);
      const savedSearch = await API.put('cwApi', `/searchitem/${search.id}`, {
        body: preppedSearch,
      });
      for (const i in this.dataStore.currentWarrant.searches) {
        if (search.id === this.dataStore.currentWarrant.searches[i].id)
          this.dataStore.currentWarrant.searches[i] = Object.assign({}, savedSearch);
        break;
      }

      // Update the warrants
      this.syncWarrantsWithCurrent();
      this._searchWarrants.next(Object.assign({}, this.dataStore).searchWarrants);
      this._currentWarrant.next(Object.assign({}, this.dataStore).currentWarrant);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  //* Evidence Methods
  /**
   * Load nested evidence items for a warrant
   * @param warrantId The ID of the warrant
   */
  private async loadEvidencesByWarrantId(warrantId: string) {
    try {
      const evidences = await API.get('cwApi', `/evidence/warrant/${warrantId}`, {});
      this.dataStore.currentWarrant.evidences = evidences;
      this.syncWarrantsWithCurrent();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Update a given evidence item
   * @param search Evidence item to be updated
   */
  public async updateEvidence(evidence: Evidence) {
    try {
      const preppedEvidence = this.utilityService.removeNullOrUndefined(evidence);
      const savedEvidence = await API.put('cwApi', `/evidence/${evidence.id}`, {
        body: preppedEvidence,
      });
      for (const i in this.dataStore.currentWarrant.evidences) {
        if (evidence.id === this.dataStore.currentWarrant.evidences[i].id)
          this.dataStore.currentWarrant.evidences[i] = Object.assign({}, savedEvidence);
        break;
      }

      // Update the warrants
      this.syncWarrantsWithCurrent();
      this._searchWarrants.next(Object.assign({}, this.dataStore).searchWarrants);
      this._currentWarrant.next(Object.assign({}, this.dataStore).currentWarrant);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  //* Helper Methods
  /**
   * Update the currentWarrant inside of searchWarrants
   */
  private syncWarrantsWithCurrent() {
    // update warrant in local storage with latest for current warrant
    for (let i = 0; i < this.dataStore.searchWarrants.length; i++) {
      if (this.dataStore.searchWarrants[i].id === this.dataStore.currentWarrant.id) {
        this.dataStore.searchWarrants[i] = this.dataStore.currentWarrant;
        break;
      }
    }
  }

  /**
   * Clear the current warrant to an empty warrant
   * @returns If the current warrant is cleared or not
   */
  public async clearCurrent() {
    try {
      this.dataStore.currentWarrant = {};
      this._currentWarrant.next(Object.assign({}, this.dataStore.currentWarrant));
      return true;
    } catch (error) {
      this.errorService.handleError(error);
      return false;
    }
  }

  /** Load Warrant from intercessor
   * @param warrantId AWS ID of the warrant to be loaded
   * @param reviewer The user who will review the warrant
   * @param court The court that is reviewing the warrant
   */
  public async requestWarrantForReview(warrantId: string, reviewer: User, court: Court) {
    try {
      await API.post('tempApi', '/intercessor/', {
        body: {
          court: {
            id: court.id,
            courtName: court.courtName,
          },
          reviewer: {
            title: reviewer.title,
            firstName: reviewer.firstName,
            lastName: reviewer.lastName,
            id: reviewer.id,
          },
          id: warrantId,
          tsCourtRequested: new Date().toISOString(),
        },
      });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /** Update Warrant on the intercessor
   * @param warrant New warrant information
   */
  public async updateReviewedWarrant(warrant: SearchWarrant) {
    const tempWarrant = new CourtResponse(
      warrant,
      this.convertEvidenceForResponse(warrant.evidences),
      this.convertSearchForResponse(warrant.searches)
    );
    try {
      await API.patch('tempApi', `/intercessor/${warrant.id}`, { body: tempWarrant });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Loads Agency data
   * @param agencyId AWS ID of the agency to be loaded
   * @returns Agency object to which the id belongs
   */
  public async loadAgencyById(agencyId: string) {
    try {
      const response = await API.get('cwApi', `/agency/${agencyId}`, {});
      const returnAgency: IAgency = response[0];
      return returnAgency;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Change the evidences to only include necessary data for a court response
   * @param evidences Warrant evidences
   * @returns Court response evidences
   */
  private convertEvidenceForResponse(evidences: Evidence[]) {
    const responseEvidences: CourtResponseEvidence[] = [];

    for (const evidence of evidences) {
      if (evidence.isStruck) responseEvidences.push(new CourtResponseEvidence(evidence));
    }

    return responseEvidences;
  }

  /**
   * Change the searches to only include necessary data for a court response
   * @param searches Warrant searches
   * @returns Court response searches
   */
  private convertSearchForResponse(searches: Search[]) {
    const responseSearches: CourtResponseSearch[] = [];

    for (const search of searches) {
      if (search.isStruck) responseSearches.push(new CourtResponseSearch(search));
    }

    return responseSearches;
  }
}
