//* Imports
// Local classes
import { ContactTime } from '../contactTime/contact-time';
import { Evidence } from '../evidence/evidence';
import { Note } from '../note/note';
import { Offense } from '../offense/offense';
import { Search } from '../search/search';
import { Validator } from '../validator/validator';

export class SearchWarrant {
  //*-----------Warrant-----------*//
  //-----Info-----//
  /** AWS ID of the search warrant */
  id?: string;
  /** What kind of warrant it is: warrant, order, gps */
  type?: string;
  /** Search warrant report number */
  reportNumber?: string;
  /** Description of the warrant */
  description?: string;
  /** City in which the warrant takes place */
  city?: string;
  /** County in which the warrant takes place */
  county?: string;
  /** State in which the warrant takes place */
  state?: string;
  /** Narrative of the warrant */
  narrative?: string;
  /** Informant narrative */
  informant?: string;
  /** If the warrant was replicated from another warrant */
  isReplicated?: boolean;

  //-----Nested Objects-----//
  /** Evidences to be collected or siezed */
  evidences?: Evidence[];
  /** Persons / Premises / Vehicles / Other objects to be searched */
  searches?: Search[];
  /** Offenses Commited */
  offenses?: Offense[];

  //-----Court Order Characteristics-----//
  /** The court is requesting photos of a subject */
  isPhoto?: boolean;
  /** The court is requesting prints of a subject */
  isPrints?: boolean;
  /** The court is requesting saliva of a subject */
  isSaliva?: boolean;
  /** The court is requesting blood of a subject */
  isBlood?: boolean;
  /** The court is requesting urine of a subject */
  isUrine?: boolean;
  /** The court is requesting hair of a subject */
  isHair?: boolean;
  /** The court is requesting handwriting of a subject */
  isHandwriting?: boolean;
  /** The court is requesting measurements of a subject */
  isMeasurements?: boolean;
  /** The court is requesting the voice of a subject */
  isVoice?: boolean;
  /** The court is requesting the footprints of a subject */
  isFootprints?: boolean;

  //-----Grounds for Issuance-----//
  /** Property was stolen or embezzled */
  isStolen?: boolean;
  /** Property was used as a means to commit an offense */
  isMeans?: boolean;
  /** Property possessed may be used as a means to commit an offense */
  isPossessed?: boolean;
  /** Property may be in possession of someone attempting to conceal it or prevent it from being discovered */
  isConcealed?: boolean;
  /** Property may constitute evidence that an offense occured */
  isEvidence?: boolean;
  /** Person sought has an outstanding warrant */
  isPerson?: boolean;

  //-----Service Time-----//
  /** When the warrant is to be served: day, night, anytime */
  serviceTime?: string;
  /** If the service time is not day, then why | Why the GPS warrant requires delayed notice */
  serviceTimeExplanation?: string;

  //-----Affiant Info-----//
  /** Badge of the affiant */
  affiantBadge?: string;
  /** Cell number of the affiant */
  affiantCell?: string;
  /** Email of the affiant */
  affiantEmail?: string;
  /** Fax of the affiant */
  affiantFax?: string;
  /** First name of the affiant */
  affiantFirstName?: string;
  /** Last name of the affiant */
  affiantLastName?: string;
  /** Title of the affiant */
  affiantTitle?: string;
  /** Training and experience */
  experience?: string;
  /** URL of the signature signed on the warrant */
  signatureImg?: string;
  /** Affiant Fax */
  myFax?: string;
  /** Affiant Email */
  myEmail?: string;
  /** Affiant Phone */
  myPhone?: string;
  /** If the document is left blank to be signed in-person */
  showNoSig?: boolean;

  //-----Agency Info-----//
  /** AWS ID of the Agency that created the warrant */
  agencyId?: string;
  /** The account that created the warrant */
  creatorId?: string;
  /** Agency status of the warrant */
  status?: string;
  /** Name of the agency */
  agencyName?: string;

  //------Optional Items-----//
  /** Delayed notice is requested */
  isDelayed?: boolean;
  /** Delayed notice explanation */
  delayedExplanation?: string;
  /** Expedited Review is requested */
  isExpedited?: boolean;
  /** Reason for expedited review is homicide */
  isExpeditedHomicide?: boolean;
  /** Reason for expedited review is an officer-involved shooting investigation */
  isExpeditedOfficer?: boolean;
  /** Reason for expedited review is law enforcement is standing by waiting for the warrant to be complete */
  isExpeditedStandy?: boolean;
  /** Reason for expedited review is public right of ways have been closed until the matter is resolved */
  isExpeditedPublic?: boolean;
  /** Reason for expedited review is people have been displaced until the matter is resolved */
  isExpeditedDisplaced?: boolean;
  /** If there is another reason for why the warrant must be expedited */
  isExpeditedOther?: boolean;
  /** Other explanation for why the warrant must be expedited */
  expeditedExplanation?: string;
  /** Requested supervisor's name for expedited review */
  expeditedSupervisorName?: string;
  /** Requested supervisor's phone number for expedited review */
  expeditedSupervisorPhone?: string;
  /** If the warrant is sealed */
  isSealed?: boolean;
  /** Explanation as to why the warrant is sealed */
  sealedExplanation?: string;
  /** No knock is requested */
  isNoKnock?: boolean;
  /** Explanation of no knock requested */
  noKnockExplanation?: string;

  //-----Approve Info-----//
  /** Number of officers on standby (non-overtime) */
  standbyOfficers?: number;
  /** Number of officers on standby on overtime */
  standbyOfficersOT?: number;
  /** Number of supervisor on standby (non-overtime) */
  standbySupervisors?: number;
  /** Number of supervisor on standby on overtime */
  standbySupervisorsOT?: number;

  //------Validation-----//
  /** Validator ensuring all the pages are complete */
  validator?: Validator;

  //-----Time Stamps-----//
  /** When the start page was first edited */
  tsSetupStart?: string;
  /** When the start page was last edited */
  tsSetupEnd?: string;
  /** When the searches page was first edited */
  tsSearchesStart?: string;
  /** When the searches page was last edited */
  tsSearchesEnd?: string;
  /** When the offenses page was first edited */
  tsOffensesStart?: string;
  /** When the offenses page was last edited */
  tsOffensesEnd?: string;
  /** When the grounds page was first edited */
  tsGroundsStart?: string;
  /** When the grounds page was last edited */
  tsGroundsEnd?: string;
  /** When the evidences page was first edited */
  tsEvidencesStart?: string;
  /** When the evidences page was last edited */
  tsEvidencesEnd?: string;
  /** When the narrative page was first edited */
  tsNarrativeStart?: string;
  /** When the narrative page was last edited */
  tsNarrativeEnd?: string;
  /** When the service page was first edited */
  tsServiceStart?: string;
  /** When the service page was last edited */
  tsServiceEnd?: string;
  /** When the submit page was first edited */
  tsSubmitStart?: string;
  /** When the submit page was last edited */
  tsSubmitEnd?: string;
  /** When the court page was first edited */
  tsCourtStart?: string;
  /** When the court page was last edited */
  tsCourtEnd?: string;
  /** When the optional item page was first edited */
  tsOptionalStart?: string;
  /** When the optional item page was last edited */
  tsOptionalEnd?: string;
  /** When the dept approve page was first edited */
  tsDeptApproveStart?: string;
  /** When the dept approve page was last edited */
  tsDeptApproveEnd?: string;
  /** Date of warrant setup */
  dateSetup?: string;
  /** Date of the warrant signature */
  dateSigned?: string;
  /** Date the warrant was submitted to a court */
  dateSubmitted?: string;
  /** Date the dept. approved the warrant */
  dateDeptApproved?: string;
  /** Date the court approved the warrant */
  dateCourtApproved?: string;
  /** Date the warrant was executed */
  dateExecuted?: string;

  //-----Analytics-----//
  /** Amount of money saved by using Centurion over conventional warrant creation from standby officer wages */
  standbySavings?: number;

  //*----------Court----------*//
  //-----Info-----//
  /** Id of the reviewing court */
  courtId?: string;
  /** Name of the destination court */
  courtName?: string;
  /** Fax number of the destination court */
  courtFax?: string;
  /** Email of the destination court */
  courtEmail?: string;
  /** Report number issued by the court */
  courtReportNumber?: string;

  //-----Notes-----//
  /** Notes seen in the public notes page */
  publicNotes?: Note[];
  /** Notes seen in the internal notes page */
  internalNotes?: Note[];

  //-----Is Struck-----//
  /** If the no knock is struck by the court */
  noKnockIsStruck?: boolean;
  /** If the service time is struck by the court */
  serviceTimeIsStruck?: boolean;
  /** If the court strikes the delayed notice request */
  isDelayedIsStruck?: boolean;
  /** If the photo request struck by the court */
  isPhotoIsStruck?: boolean;
  /** If the print request struck by the court */
  isPrintsIsStruck?: boolean;
  /** If the saliva request struck by the court */
  isSalivaIsStruck?: boolean;
  /** If the blood request struck by the court */
  isBloodIsStruck?: boolean;
  /** If the urine request struck by the court */
  isUrineIsStruck?: boolean;
  /** If the hair request struck by the court */
  isHairIsStruck?: boolean;
  /** If the Handwriting request struck by the court */
  isHandwritingIsStruck?: boolean;
  /** If the measurements request struck by the court */
  isMeasurementsIsStruck?: boolean;
  /** If the voice request struck by the court */
  isVoiceIsStruck?: boolean;
  /** If the foorprints request struck by the court */
  isFootprintsIsStruck?: boolean;
  /** If the request to be sealed has been struck by the court */
  sealedIsStruck?: boolean;

  //-----Review Info-----//
  /** Court's id of the reviewer(judge) who approved/denied the warrant */
  reviewerId?: string;
  /** Title of the reviewer(judge) who approved/denied the warrant */
  reviewerTitle?: string;
  /** First name of the reviewer(judge) who approved/denied the warrant */
  reviewerFirstName?: string;
  /** Name of the reviewer(judge) who approved/denied the warrant */
  reviewerLastName?: string;
  /** If the warrant was approved or not */
  courtApproved?: boolean;
  /** Information on why the warrant was denied */
  courtExplanation?: string;
  /** Status of the warrant within the court: submitted, approved, denied */
  courtStatus?: string;
  /** Times the officer was contacted about the warrant */
  contactedTimes?: ContactTime[];
  /** Times the oath was given to the judge */
  oathTimes?: ContactTime[];
  /** Times the judge attempted to contact the officer and was unsuccessful */
  noAnswerTimes?: ContactTime[];
  /** Reviewer signature as base64 string */
  reviewerSignature?: string;

  //-----Time Stamps-----//
  /** Timestamp of when the court requested warrant to review */
  tsCourtRequested?: string;
  /** Timestamp of when the court review was complete */
  tsCourtReviewed?: string;
  /** Date/Time the officer was called */
  tsOfficerCalled?: string;
  /** Time the warrant was submitted to the court */
  tsSubmittedToCourt?: string;
}
