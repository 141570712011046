import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private timeout: number;
  private onTimeout: any;
  private eventHandler: any;
  private interval: any;
  private timeoutTracker: any;
  private toastController: ToastController;

  setValues(timeout, onTimeout) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem('_expiredTime'), 10);
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  idleCount = new Observable((observer) => {
    const expiredTime = parseInt(localStorage.getItem('_expiredTime'), 10);
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();

    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem('_expiredTime'), 10);
      observer.next((expiredTime - Date.now()) / 1000);
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  });

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem('_expiredTime', (Date.now() + this.timeout * 1000).toString());
    }, 300);
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}
