/* eslint-disable prettier/prettier */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/authGuard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/public/login/login.module').then( m => m.LoginPageModule) },
  { path: 'profile', loadChildren: () => import('./pages/secure/user/profile/profile.module').then((m) => m.ProfilePageModule), canActivate: [AuthGuard] },
  { path: 'change-password-modal', loadChildren: () => import('./pages/secure/user/modals/change-password-modal/change-password-modal.module').then( m => m.ChangePasswordModalPageModule), canActivate: [AuthGuard] },
  { path: 'signature-modal', loadChildren: () => import('./pages/secure/user/modals/signature-modal/signature-modal/signature-modal.module').then( m => m.SignatureModalPageModule), canActivate: [AuthGuard] },
  { path: 'signature-pad-modal', loadChildren: () => import('./pages/secure/user/modals/signature-pad-modal/signature-pad-modal/signature-pad-modal.module').then( m => m.SignaturePadModalPageModule), canActivate: [AuthGuard] },
  { path: 'dashboard', loadChildren: () => import('./pages/secure/dashboard/dashboard.module').then( m => m.DashboardPageModule), canActivate: [AuthGuard] },
  { path: 'change-password-modal', loadChildren: () => import('./pages/public/modals/change-password-modal/change-password-modal.module').then( m => m.ChangePasswordModalPageModule), canActivate: [AuthGuard] },
  //* Warrant
  { path: 'warrant/preview', loadChildren: () => import('./pages/secure/warrant/preview/preview.module').then( m => m.PreviewPageModule), canActivate: [AuthGuard] },
  { path: 'warrant/probable-cause', loadChildren: () => import('./pages/secure/warrant/probable-cause/probable-cause.module').then( m => m.ProbableCausePageModule), canActivate: [AuthGuard] },
  { path: 'warrant/edit-warrant', loadChildren: () => import('./pages/secure/warrant/edit-warrant/edit-warrant.module').then( m => m.EditWarrantPageModule), canActivate: [AuthGuard] },
  { path: 'warrant/public-notes', loadChildren: () => import('./pages/secure/warrant/public-notes/public-notes.module').then( m => m.PublicNotesPageModule), canActivate: [AuthGuard] },
  { path: 'warrant/internal-notes', loadChildren: () => import('./pages/secure/warrant/internal-notes/internal-notes.module').then( m => m.InternalNotesPageModule), canActivate: [AuthGuard] },
  { path: 'warrant/court-tracking', loadChildren: () => import('./pages/secure/warrant/court-tracking/court-tracking.module').then( m => m.CourtTrackingPageModule), canActivate: [AuthGuard] },
  { path: 'warrant/approve', loadChildren: () => import('./pages/secure/warrant/approve/approve.module').then( m => m.ApprovePageModule), canActivate: [AuthGuard] },
  // Modals
  { path: 'warrant/note-modal', loadChildren: () => import('./pages/secure/warrant/modals/note-modal/note-modal.module').then( m => m.NoteModalPageModule), canActivate: [AuthGuard] },
  { path: 'warrant/deny-modal',loadChildren: () => import('./pages/secure/warrant/modals/deny-modal/deny-modal.module').then( m => m.DenyModalPageModule), canActivate: [AuthGuard] },
  //* Order
  { path: 'order/preview', loadChildren: () => import('./pages/secure/order/preview/preview.module').then( m => m.PreviewPageModule), canActivate: [AuthGuard] },
  { path: 'order/probable-cause', loadChildren: () => import('./pages/secure/order/probable-cause/probable-cause.module').then( m => m.ProbableCausePageModule), canActivate: [AuthGuard] },
  { path: 'order/edit-warrant', loadChildren: () => import('./pages/secure/order/edit-warrant/edit-warrant.module').then( m => m.EditWarrantPageModule), canActivate: [AuthGuard] },
  { path: 'order/public-notes', loadChildren: () => import('./pages/secure/order/public-notes/public-notes.module').then( m => m.PublicNotesPageModule), canActivate: [AuthGuard] },
  { path: 'order/internal-notes', loadChildren: () => import('./pages/secure/order/internal-notes/internal-notes.module').then( m => m.InternalNotesPageModule), canActivate: [AuthGuard] },
  { path: 'order/court-tracking', loadChildren: () => import('./pages/secure/order/court-tracking/court-tracking.module').then( m => m.CourtTrackingPageModule), canActivate: [AuthGuard] },
  { path: 'order/approve', loadChildren: () => import('./pages/secure/order/approve/approve.module').then( m => m.ApprovePageModule), canActivate: [AuthGuard] },
  // Modals
  { path: 'order/note-modal', loadChildren: () => import('./pages/secure/order/modals/note-modal/note-modal.module').then( m => m.NoteModalPageModule), canActivate: [AuthGuard]},
  { path: 'order/deny-modal', loadChildren: () => import('./pages/secure/order/modals/deny-modal/deny-modal.module').then( m => m.DenyModalPageModule) },
  //* Gps
  { path: 'gps/preview', loadChildren: () => import('./pages/secure/gps/preview/preview.module').then( m => m.PreviewPageModule), canActivate: [AuthGuard] },
  { path: 'gps/probable-cause', loadChildren: () => import('./pages/secure/gps/probable-cause/probable-cause.module').then( m => m.ProbableCausePageModule), canActivate: [AuthGuard] },
  { path: 'gps/edit-warrant', loadChildren: () => import('./pages/secure/gps/edit-warrant/edit-warrant.module').then( m => m.EditWarrantPageModule), canActivate: [AuthGuard] },
  { path: 'gps/public-notes', loadChildren: () => import('./pages/secure/gps/public-notes/public-notes.module').then( m => m.PublicNotesPageModule), canActivate: [AuthGuard] },
  { path: 'gps/internal-notes', loadChildren: () => import('./pages/secure/gps/internal-notes/internal-notes.module').then( m => m.InternalNotesPageModule), canActivate: [AuthGuard] },
  { path: 'gps/court-tracking', loadChildren: () => import('./pages/secure/gps/court-tracking/court-tracking.module').then( m => m.CourtTrackingPageModule), canActivate: [AuthGuard] },
  { path: 'gps/approve', loadChildren: () => import('./pages/secure/gps/approve/approve.module').then( m => m.ApprovePageModule), canActivate: [AuthGuard] },
  // Modals
  { path: 'gps/note-modal', loadChildren: () => import('./pages/secure/gps/modals/note-modal/note-modal.module').then( m => m.NoteModalPageModule), canActivate: [AuthGuard] },
  { path: 'gps/deny-modal', loadChildren: () => import('./pages/secure/gps/modals/deny-modal/deny-modal.module').then( m => m.DenyModalPageModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
