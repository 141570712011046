//* Imports
// Angular
import { Injectable } from '@angular/core';
import {
  ToastController,
  ToastOptions,
  LoadingController,
  AlertController,
  AlertOptions,
} from '@ionic/angular';
// Libphonenumber-js
import { AsYouType } from 'libphonenumber-js';
// Uuid
import { v4 as uuidv4 } from 'uuid';
// Local pipes
import { PhonePipe } from 'src/app/pipes/phone/phone.pipe';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  //* Variable Declaration
  loader: HTMLIonLoadingElement;

  //* Constructor
  constructor(
    private toastController: ToastController,
    private loadingController: LoadingController,
    private phonePipe: PhonePipe,
    private alertController: AlertController
  ) {}

  //* Methods
  /**
   * showToast
   * @description shows a confirmation message via toast
   * @param options {ToastOptions} confirmation option properties
   */
  async showToast(options: ToastOptions) {
    const toast = await this.toastController.create({
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          },
        },
      ],
      color: options.color != null ? options.color : 'primary',
      duration: options.duration,
      header: options.header,
      message: options.message,
      position: options.position,
    });
    toast.present();
  }

  /**
   * Show a toast letting the user know that an error has occured
   * @param message The message to show the user
   */
  async showError(message: string) {
    this.showToast({
      message: message,
      position: 'top',
      duration: 2500,
      color: 'danger',
    });
  }

  /**
   * Show a toast letting the user know that a successful action has occured
   * @param message The message to show the user
   */
  async showSuccess(message: string) {
    this.showToast({
      message: message,
      position: 'top',
      duration: 2500,
      color: 'success',
    });
  }

  /**
   * Check a new password and its comprison to see if they match.
   * If they do, return true, if they do not, return false and an error toast
   * @param password New Password
   * @param passwordCompare New Password Comparison
   * @returns If the passwords match or not
   */
  passwordCompare(password: string, passwordCompare: string): boolean {
    if (password == passwordCompare) {
      return true;
    } else {
      this.showError('Passwords do not match');
      return false;
    }
  }

  /**
   * Start a loading message with a given message
   * @param message Message to show user while loading
   */
  async startLoading(message: string) {
    this.loader = await this.loadingController.create({
      message: message,
    });
    this.loader.present();
  }

  /**
   * Dismiss the current loader
   */
  async endLoading() {
    this.loader.dismiss();
  }

  /**
   * Determine if response is valid (has success result of true)
   * @param result {Object} - result object to assess
   * @returns boolean - true if valid, false otherwise
   */
  public isValidResponse(result: any): boolean {
    if (
      typeof result !== 'undefined' &&
      ((typeof result.success !== 'undefined' && result.success) || result)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Sets display of phone - +16025551212 becomes (602) 555-1212
   * @param val {string} Phone number to be prettified
   */
  public prettifyPhone(val: string): string {
    return this.phonePipe.transform(val.replace('+1', ''));
  }
  /**
   * Sets data from pretty display - (602) 555-1212 becomes +16025551212
   * @param val {string} Phone number to be uglified
   */
  public uglifyPhone(val: string): string {
    if (!val || val == '') {
      return '';
    }
    const asYouType = new AsYouType('US');
    asYouType.input(val);
    return asYouType.getNumber().number.toString();
  }

  /**
   * Show a custom alert
   * @description shows configurable alert message for user
   * @param options {object} confirmation option properties
   * @returns object
   */
  async showCustomAlert(options: AlertOptions) {
    const alert = await this.alertController.create({
      message: options.message,
      header: options.header != null ? options.header : 'Error',
      subHeader: options.subHeader != null ? options.subHeader : '',
      translucent: options.translucent != null ? options.translucent : false,
      buttons: options.buttons != null ? options.buttons : ['Dismiss'],
    });
    await alert.present();
  }

  /**
   * Show custom alert and wait for the user to dismiss it
   * @description shows configurable alert message for user
   * @param options {object} confirmation option properties
   * @returns object
   */
  async showCustomDismissAlert(options: AlertOptions) {
    const alert = await this.alertController.create({
      message: options.message,
      header: options.header != null ? options.header : 'Error',
      subHeader: options.subHeader != null ? options.subHeader : '',
      translucent: options.translucent != null ? options.translucent : false,
      buttons: options.buttons != null ? options.buttons : ['Dismiss'],
    });
    await alert.present();
    await alert.onDidDismiss();
  }

  /**
   * removeNullOrUndefined
   * @description Remove keys with blank or undefined values
   * @param obj {object} Object to be processed
   * @returns {object} The object with the null/undefined values removed
   */
  public removeNullOrUndefined(obj: any) {
    Object.keys(obj).forEach((field) => {
      if (obj[field] === null || typeof obj[field] === 'undefined' || obj[field] === '')
        delete obj[field];
    });
    return obj;
  }

  /**
   * Compare if two objects are equal in value
   * @param object1 Object 1
   * @param object2 Object 2
   * @returns If the two objects are equal
   */
  public compareObjects(object1: any, object2: any) {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  /**
   * Copy an object using JSON
   * @param object Object to be copied
   * @returns Copy
   */
  public copyByValue(object: any) {
    const copy = JSON.parse(JSON.stringify(object));
    return copy;
  }

  /**
   * Create a unique id to be used for objects
   * @returns uuid
   */
  public getUniqueId() {
    return uuidv4();
  }

  /**
   * Get the full string used for display purposes based on type
   * @param warrantType Warrant type: warrant, gps, order
   * @returns The string to display to the user
   */
  public getStringType(warrantType: string) {
    switch (warrantType) {
      case 'warrant':
        return 'Search Warrant';
      case 'gps':
        return 'GPS';
      case 'order':
        return 'Court Order Physical Characteristics';
      default:
        break;
    }
  }

  /**
   * Capitalize the first letter in a string
   * @param stringToCapitalize String to capitalize
   * @returns Capitalized String
   */
  public capitalizeString(stringToCapitalize: string) {
    return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
  }

  /**
   * Check if a new password is valid
   * @param userId The username of the account
   * @param password The new password
   * @param passwordCompare The new password's comparison check
   * @returns If the password is valid or not
   */
  public checkPasswordValidity(userId: string, password: string, passwordCompare: string) {
    if (password.length < 8) {
      this.showToast({
        message: 'The password must be at least 8 characters long',
        position: 'top',
        duration: 2500,
        color: 'danger',
      });
      return false;
    } else if (userId && userId.toLowerCase() == password.toLowerCase()) {
      this.showToast({
        message: 'The password may not be the same as the login email',
        position: 'top',
        duration: 2500,
        color: 'danger',
      });
      return false;
    } else if (password != passwordCompare) {
      this.showToast({
        message: 'Passwords do not match',
        position: 'top',
        duration: 2500,
        color: 'danger',
      });
      return false;
    } else {
      return true;
    }
  }

  /**
   * Check if the password is a specified age
   * @param pwChangeDate Date the password was last changed
   * @returns If the password must be reset
   */
  public passwordAgeCheck(pwChangeDate: string) {
    const changeDate = new Date(pwChangeDate);
    const diffMs = Math.abs(new Date().valueOf() - changeDate.valueOf());
    const daysSinceChange = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    if (daysSinceChange > 90) {
      return true;
    } else {
      return false;
    }
  }
}
